<template>
  <v-container fluid class="audit-logs pa-0 height-auto">
    <ERow no-gutters>
      <ECol cols="12" class="py-2">
        <v-card flat>
          <ERow class="mx-0" align="center">
            <ECol cols="12" class="py-1 pl-4">
              <v-card class="d-flex flex-row" color="lighten-2" flat tile>
                <v-card
                  class="pa-1 subtitle-2 grey--text text--darken-2 pl-0"
                  flat
                  tile
                >
                  From:
                </v-card>
                <v-card class="pa-1" flat tile>
                  <VueCtkDateTimePicker
                    v-model="fromDatetime"
                    hint="From"
                    format="YYYY-MM-DDTHH:mm:ss"
                    formatted="DD/MM/YYYY HH:mm"
                    button-color="#1976d2"
                    color="#1976d2"
                    no-button-now
                    no-shortcuts
                    no-header
                  >
                    <v-text-field class="caption mt-0 pt-0 border-bottom" />
                  </VueCtkDateTimePicker>
                </v-card>

                <v-card
                  class="pa-1 ml-2 subtitle-2 grey--text text--darken-2"
                  flat
                  tile
                >
                  To:
                </v-card>
                <v-card class="pa-1" flat tile>
                  <VueCtkDateTimePicker
                    v-model="toDatetime"
                    format="YYYY-MM-DDTHH:mm:ss"
                    formatted="DD/MM/YYYY HH:mm"
                    button-color="#1976d2"
                    color="#1976d2"
                    no-button-now
                    no-shortcuts
                    no-header
                    no-label
                  >
                    <v-text-field class="caption mt-0 pt-0 border-bottom" />
                  </VueCtkDateTimePicker>
                </v-card>
                <v-card
                  class="pa-1 ml-2 subtitle-2 grey--text text--darken-2"
                  flat
                  tile
                >
                  Project:
                </v-card>
                <v-card class="px-1" flat tile>
                  <v-text-field
                    v-model="byProject"
                    class="mt-0 caption"
                    placeholder="Project name"
                    dense
                    single-line
                    hide-details
                    @keyup="handleSearch"
                  >
                  </v-text-field>
                </v-card>
                <v-card
                  class="pa-1 ml-2 subtitle-2 grey--text text--darken-2"
                  flat
                  tile
                >
                  Camera:
                </v-card>
                <v-card class="px-1" flat tile>
                  <v-text-field
                    v-model="byCamera"
                    class="mt-0 caption"
                    placeholder="Camera name"
                    dense
                    single-line
                    hide-details
                    @keyup="handleSearch"
                  >
                  </v-text-field>
                </v-card>
                <v-card
                  class="pa-1 ml-2 subtitle-2 grey--text text--darken-2"
                  flat
                  tile
                >
                  Router:
                </v-card>
                <v-card class="px-1" flat tile>
                  <v-text-field
                    v-model="byRouter"
                    class="mt-0 caption"
                    placeholder="Router serial number"
                    dense
                    single-line
                    hide-details
                    @keyup="handleSearch"
                  >
                  </v-text-field>
                </v-card>
                <v-card
                  class="pa-1 ml-2 subtitle-2 grey--text text--darken-2"
                  flat
                  tile
                >
                  Entity:
                </v-card>
                <v-card class="px-1 pa-0" flat tile>
                  <v-select
                    v-model="logEntity"
                    :items="entities"
                    multiple
                    small-chips
                    deletable-chips
                    dense
                    class="mt-0 caption"
                    hide-details
                    @change="getLogs"
                  >
                  </v-select>
                </v-card>
              </v-card>
            </ECol>
          </ERow>
        </v-card>
      </ECol>
    </ERow>
    <ERow no-gutters>
      <ECol cols="12" class="px-0">
        <v-data-table
          name="auditLogs"
          dense
          :headers="headers"
          :items="logs"
          :options.sync="options"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          item-key="id"
          :single-expand="true"
          :expanded.sync="expanded"
          :must-sort="true"
          class="custom-data-table"
          show-expand
          :loading="loading"
          loading-text="Please wait..."
          :server-items-length="total"
          :footer-props="{
            'items-per-page-options': [50, 100, 200],
          }"
        >
          <template #item.data-table-expand="{ item, isExpanded, expand }">
            <v-icon
              v-if="item.canExpand && !isExpanded"
              x-small
              color="primary"
              @click="expand(true)"
            >
              fa-plus
            </v-icon>
            <v-icon
              v-if="item.canExpand && isExpanded"
              role="button"
              x-small
              color="primary"
              @click="expand(false)"
            >
              fa-minus
            </v-icon>
          </template>
          <template #item.insertedAt="{ item }">
            {{ formatDate(item.insertedAt) }}
          </template>
          <template #item.who="{ item }">
            <span v-if="item.who">
              {{ item.who }}
            </span>
            <span v-else> System </span>
          </template>
          <template #item.entity="{ item }">
            {{ findEntity(item.entity) }}
          </template>
          <template #item.ip="{ item }">
            <span v-if="item.userDetails && item.userDetails.ip">
              {{ item.userDetails.ip }}
            </span>
            <span v-else>-</span>
          </template>
          <template #item.event="{ item }">
            <component
              :is="item.action"
              v-if="item.action"
              :item="item"
              :camera-id="cameraId"
              @open-camera-dialog="
                (camera) => cameraDialogStore.openCameraDialog({ camera })
              "
              @open-router-dialog="routerStore.openDialog($event)"
              @openSmsHistoryDialog="openSmsHistoryDialog"
            />
            <div v-else>
              <EReadMore :content="item.details.message" :max-length="60" />
            </div>
          </template>
          <template #expanded-item="{ item }">
            <td :colspan="7" class="pl-0 pr-0">
              <AuditLogsDiff :item="item" />
            </td>
          </template>
        </v-data-table>
        <SmsHistoryDialog
          v-model="showSmsHistoryDialog"
          :sim="selectedSimNumber"
        />
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import { AdminApi } from "@evercam/shared/api/adminApi"
import { debounce } from "@evercam/shared/utils"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import AuditLogsHeader from "@/components/auditLogs/auditLogsHeaders"
import AuditLogsDiff from "@/components/auditLogs/AuditLogsDiff"
import LOG_TYPES from "@evercam/shared/constants/logTypes"
import { decamelize } from "humps"
import SmsHistoryDialog from "@/components/sims/SmsHistoryDialog"
import LogTypeCreate from "@/components/auditLogs/logTypes/Create"
import LogTypeUpdate from "@/components/auditLogs/logTypes/Update"
import LogTypeDelete from "@/components/auditLogs/logTypes/Delete"
import LogTypeCrUpdated from "@/components/auditLogs/logTypes/CrUpdated"
import LogTypeCustom from "@/components/auditLogs/logTypes/Custom"
import LogTypePublicNote from "@/components/auditLogs/logTypes/PublicNote"
import LogTypeShare from "@/components/auditLogs/logTypes/Share"
import LogTypeAssign from "@/components/auditLogs/logTypes/Assign"
import LogTypeUnassign from "@/components/auditLogs/logTypes/Unassign"
import LogTypeShareRequests from "@/components/auditLogs/logTypes/ShareRequests"
import LogTypeUpdateShare from "@/components/auditLogs/logTypes/UpdateShare"
import LogTypeDeleteShare from "@/components/auditLogs/logTypes/DeleteShare"
import LogTypeDeleteShareRequests from "@/components/auditLogs/logTypes/DeleteShareRequests"
import LogTypeOnline from "@/components/auditLogs/logTypes/Online"
import LogTypeOffline from "@/components/auditLogs/logTypes/Offline"
import LogTypeChangedStatus from "@/components/auditLogs/logTypes/ChangedStatus"
import LogTypeCameraRouter from "@/components/auditLogs/logTypes/CameraRouter"
import LogTypeSimRouter from "@/components/auditLogs/logTypes/SimRouter"
import LogTypeTransferOwnership from "@/components/auditLogs/logTypes/TransferOwnership"
import LogTypeLogin from "@/components/auditLogs/logTypes/Login"
import LogTypeLogout from "@/components/auditLogs/logTypes/Logout"
import LogTypeDeviceSettings from "@/components/auditLogs/logTypes/DeviceSettings"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useRouterStore } from "@/stores/router"

export default {
  name: "AuditLogsTable",
  components: {
    AuditLogsDiff,
    VueCtkDateTimePicker,
    SmsHistoryDialog,
    create: LogTypeCreate,
    update: LogTypeUpdate,
    delete: LogTypeDelete,
    share: LogTypeShare,
    assign: LogTypeAssign,
    unassign: LogTypeUnassign,
    delete_share_request: LogTypeDeleteShareRequests,
    delete_share: LogTypeDeleteShare,
    update_share: LogTypeUpdateShare,
    share_request: LogTypeShareRequests,
    online: LogTypeOnline,
    offline: LogTypeOffline,
    waiting_for_site_visit: LogTypeChangedStatus,
    under_maintenance: LogTypeChangedStatus,
    decommissioned: LogTypeChangedStatus,
    waiting: LogTypeChangedStatus,
    on_hold: LogTypeChangedStatus,
    link_camera_with_router: LogTypeCameraRouter,
    unlink_camera_from_router: LogTypeCameraRouter,
    link_sim_with_router: LogTypeSimRouter,
    unlink_sim_from_router: LogTypeSimRouter,
    transfer_ownership: LogTypeTransferOwnership,
    login: LogTypeLogin,
    logout: LogTypeLogout,
    device_settings: LogTypeDeviceSettings,
    custom: LogTypeCustom,
    public_note: LogTypePublicNote,
    cr_updated: LogTypeCrUpdated,
  },
  props: {
    routerId: {
      type: Number,
      default: null,
    },
    cameraId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      headers: AuditLogsHeader,
      expanded: [],
      logs: [],
      sortBy: "insertedAt",
      sortDesc: true,
      total: 0,
      options: {
        sortBy: ["insertedAt"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 50,
      },
      toDatetime: this.$moment(new Date()),
      fromDatetime: this.$moment(new Date()).subtract({ days: 15 }),
      logEntity: "",
      byRouter: "",
      byCamera: "",
      byProject: "",
      logTypes: LOG_TYPES,
      showSmsHistoryDialog: false,
      selectedSimNumber: "",
      entities: [
        { value: "cameras", text: "Cameras" },
        { value: "routers", text: "Routers" },
        { value: "sims", text: "SIMs" },
        { value: "projects", text: "Projects" },
        { value: "nvr", text: "NVR" },
        { value: "archives", text: "Archives" },
        { value: "users", text: "Users" },
        { value: "rois", text: "Rois" },
        { value: "layers", text: "Layers" },
        { value: "roles", text: "Roles" },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useRouterStore),
    datesFilter() {
      if (
        this.$moment(this.fromDatetime).isAfter(this.$moment(this.toDatetime))
      ) {
        return {
          from: `${this.$moment(this.toDatetime).format(
            "YYYY-MM-DDTHH:mm:ss"
          )}.000Z`,
          to: `${this.$moment(this.fromDatetime).format(
            "YYYY-MM-DDTHH:mm:ss"
          )}.000Z`,
        }
      }

      return {
        from: `${this.$moment(this.fromDatetime).format(
          "YYYY-MM-DDTHH:mm:ss"
        )}.000Z`,
        to: `${this.$moment(this.toDatetime).format(
          "YYYY-MM-DDTHH:mm:ss"
        )}.000Z`,
      }
    },
  },
  watch: {
    options: {
      immediate: true,
      async handler() {
        if (!this.loading) {
          this.getLogs()
        }
      },
    },
    fromDatetime: {
      immediate: true,
      handler(val, oldVal) {
        if (this.loading || val === oldVal) {
          return
        }
        this.getLogs()
      },
    },
    toDatetime: {
      immediate: true,
      handler(val, oldVal) {
        if (this.loading || val === oldVal) {
          return
        }
        this.getLogs()
      },
    },
  },
  methods: {
    async getLogs() {
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        sortBy = decamelize(sortBy[0])
        this.loading = true
        let payload = {
          params: {
            sort: `${sortBy}|${this.whichSort(sortDesc[0])}`,
            limit: itemsPerPage,
            page: page,
            routerId: this.routerId ? this.routerId : null,
            cameraId: this.cameraId ? this.cameraId : null,
            entity: this.logEntity,
            byRouter: this.byRouter,
            byCamera: this.byCamera,
            byProject: this.byProject,
            ...this.datesFilter,
          },
        }
        const logs = await AdminApi.auditLogs.getAuditLogs(payload)
        this.total = logs.total
        this.logs = this.addOnlineTimespan(logs)
      } catch (error) {
        this.$notifications.error({
          text: "Logs could not be loaded!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    addOnlineTimespan(logs) {
      let logsList = []
      let latestOnlineLogIndex = -1
      logs.items.forEach((log, index) => {
        if (log.action === this.logTypes.ONLINE) {
          latestOnlineLogIndex = index
        }

        if (log.action === this.logTypes.OFFLINE && latestOnlineLogIndex > -1) {
          logsList[latestOnlineLogIndex].details = {
            online: logsList[latestOnlineLogIndex].insertedAt,
            offline: log.insertedAt,
            ...log.details,
          }
          latestOnlineLogIndex = -1
        }

        logsList.push({
          id: log.id,
          action: log.action,
          entity: log.entity,
          userDetails: log.userDetails,
          insertedAt: this.$moment(log.insertedAt).format(
            "ddd, MMM DD, YYYY, HH:mm:ss"
          ),
          details: log.details,
          who: log.who,
          canExpand: this.canExpand(log),
        })
      })

      return logsList
    },
    handleSearch: debounce(function () {
      this.getLogs()
    }, 500),
    canExpand(item) {
      if (item.entity == "archives") return false

      return (
        (item.action === LOG_TYPES.UPDATE &&
          (item.details?.camera?.settings != undefined ||
            item.details?.settings != undefined ||
            item.details?.project?.settings != undefined ||
            item.details?.user?.settings != undefined ||
            item.details?.roi?.settings != undefined ||
            !!item.details?.router?.settings)) ||
        (item.action === LOG_TYPES.DELETE &&
          (item.details?.camera != undefined ||
            item.details?.project != undefined ||
            item.details?.config != undefined ||
            item.details?.user != undefined ||
            item.details?.sim != undefined)) ||
        (item.action === LOG_TYPES.DEVICE_SETTINGS &&
          item.details?.router?.deviceSettings != undefined) ||
        (item.action === LOG_TYPES.OFFLINE &&
          item.details?.camera?.errorBody != undefined) ||
        (item.action === LOG_TYPES.DEVICE_SETTINGS && item.entity === "nvr")
      )
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
    openSmsHistoryDialog(item) {
      this.showSmsHistoryDialog = true
      this.selectedSimNumber = item
    },
    findEntity(entity) {
      const foundEntity = this.entities.find(({ value }) => value === entity)

      return foundEntity.text
    },
  },
}
</script>
