<template>
  <div>
    <div v-if="item.entity == 'cameras'">
      Camera
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.exid || item.details.camera.exid)"
      >
        {{ item.details.name || item.details.camera.name }}
      </span>
      created.
    </div>
    <div v-if="item.details.router">
      Router
      <span
        class="sim-hover primary--text"
        @click="$emit('open-router-dialog', item.details.router)"
      >
        {{ item.details.router.serialNumber }}
      </span>
      created.
    </div>
    <div v-if="item.details.sim">
      New SIM
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            class="primary--text sim-hover"
            v-bind="attrs"
            @click="openSmsHistoryDialog(item.details.sim.number)"
            v-on="on"
          >
            {{ item.details.sim.number }}
          </span>
        </template>
        Open SMS History
      </v-tooltip>
      added.
    </div>
    <div v-if="item.details.project">
      Project
      <span
        class="cursor-pointer primary--text"
        @click="projectStore.openDialog(item.details.project.exid)"
      >
        {{ item.details.project.name }}
      </span>
      created.
    </div>
    <div v-if="item.entity == 'users'">
      User
      <span class="sim-hover primary--text">
        {{ item.who }}
      </span>
      created.
    </div>
    <div v-if="item.entity == 'archives'">
      {{ toTitleCase(item.details.type) }} created for
      <span
        class="sim-hover primary--text"
        @click="openCameraDialog(item.details.camera.exid)"
      >
        {{ item.details.camera.name }} </span
      >.
    </div>
    <div v-if="item.details && item.entity === 'rois'">
      User <span class="sim-hover primary--text"> {{ item.who }} </span>
      created a new roi
    </div>
    <div v-if="item.entity == 'layers' && item.details">
      User <span class="sim-hover primary--text"> {{ item.who }} </span> created
      new layer {{ item.details.name }}
      <span v-if="item.details.displayName">
        :{{ item.details.displayName }}
      </span>
      , id: {{ item.details.layerId }}
    </div>
    <div v-if="item.entity == 'roles'">
      Role
      <span class="sim-hover primary--text">
        {{ item.details.name }}
      </span>
      created.
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedCamera: "",
      showSmsHistoryDialog: false,
      selectedSimNumber: "",
    }
  },
  computed: {
    ...mapStores(useProjectStore),
  },
  methods: {
    openCameraDialog(cameraId) {
      this.$emit("open-camera-dialog", cameraId)
    },
    openSmsHistoryDialog(item) {
      this.$emit("openSmsHistoryDialog", item)
    },
  },
}
</script>
